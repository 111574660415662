'use client';
import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  margin: auto ${props => props.theme.spacing(4)};

  @media ${props => props.theme.tablet} {
    margin: initial;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 500px;
    box-sizing: border-box;
  }
`;

export const Header = styled.h2`
  ${({ theme }) => theme.typography.heading5};
  color: ${({ variant, theme }) =>
    variant === 'primary'
      ? theme.colors.text.primary
      : theme.colors.status.errorPrimary};
`;

export const Paragraph = styled.p`
  ${({ theme }) => theme.typography.body};
  margin: ${({ theme }) => theme.spacing(5, 0)};

  @media ${props => props.theme.tablet} {
    margin: ${({ theme }) => theme.spacing(5, 6)};
  }
`;

export const SpanContainer = styled.span`
  margin: ${({ theme }) => theme.spacing(0)};

  @media ${({ theme }) => theme.tablet} {
    margin: ${({ theme }) => theme.spacing(0, 1)};
  }
`;
