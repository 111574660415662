'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Cell, Checkbox, Grid, Input, Dropdown } from '@web/atoms';
import { handlePhoneNumberFormat } from '../../customer/functions';
import { TooltipIcon } from '../TooltipIcon';
import {
  addressSchema,
  addressWithNameSchema,
  phoneNumberValidation,
} from './schema';
import { SMSTermsAndConditions, SMSTermsAndConditionsLink } from './styles';

export const ShippingAddressSchema = addressSchema;
export const ShippingAddressWithNameSchema = addressWithNameSchema;
export const ShippingAddressPhoneNumberValidation = phoneNumberValidation;

export const ShippingAddress = ({
  labelFirstName,
  labelLastName,
  labelAddress,
  labelApt,
  labelZipCode,
  labelCity,
  labelState,
  stateOptions = [],
  labelPhoneNumber,
  labelSMSUpdates,
  labelSMSTerms,
}) => {
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  return (
    <Grid gap={{ row: 4, column: 4 }} columns={2}>
      {labelFirstName && (
        <Cell columns={{ tablet: 1, mobile: 2 }}>
          <Controller
            control={control}
            name="firstName"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Input
                {...field}
                labelText={labelFirstName}
                status={error?.message && 'error'}
                error={error?.message}
                ref={ref}
                autocomplete="shipping given-name"
              />
            )}
          />
        </Cell>
      )}
      {labelLastName && (
        <Cell columns={{ tablet: 1, mobile: 2 }}>
          <Controller
            control={control}
            name="lastName"
            render={({ field: { ref, ...field }, fieldState: { error } }) => (
              <Input
                {...field}
                labelText={labelLastName}
                status={error?.message && 'error'}
                error={error?.message}
                ref={ref}
                autocomplete="shipping family-name"
              />
            )}
          />
        </Cell>
      )}
      <Cell columns={2}>
        <Controller
          control={control}
          name="address"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              {...field}
              labelText={labelAddress}
              onChange={e => {
                if (errors.address?.type === 'server') {
                  clearErrors('address');
                }
                field.onChange(e);
              }}
              status={error?.message && 'error'}
              error={error?.message}
              ref={ref}
              autocomplete="shipping address-line1"
            />
          )}
        />
      </Cell>
      <Cell columns={1}>
        <Controller
          control={control}
          name="apt"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              {...field}
              labelText={labelApt}
              status={error?.message && 'error'}
              error={error?.message}
              ref={ref}
              autocomplete="shipping address-line2"
            />
          )}
        />
      </Cell>
      <Cell columns={1}>
        <Controller
          control={control}
          name="zipCode"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              {...field}
              labelText={labelZipCode}
              onChange={value => {
                if (errors.zipCode?.type === 'server') {
                  clearErrors('zipCode');
                }
                field.onChange(value);
              }}
              status={error?.message && 'error'}
              error={error?.message}
              ref={ref}
              autocomplete="shipping postal-code"
            />
          )}
        />
      </Cell>
      <Cell columns={1}>
        <Controller
          control={control}
          name="city"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              {...field}
              labelText={labelCity}
              status={error?.message && 'error'}
              error={error?.message}
              ref={ref}
              autocomplete="shipping address-line3"
            />
          )}
        />
      </Cell>
      <Cell columns={1}>
        <Controller
          control={control}
          name="state"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Dropdown
              {...field}
              options={stateOptions}
              icon={
                <TooltipIcon placement={'top'} colorVariant={'light'}>
                  Ollie currently only ships to the 48 U.S. continental states.
                </TooltipIcon>
              }
              label={labelState}
              noSelectionLabel={labelState}
              selected={field?.value ? field.value.toString() : ''}
              error={error?.message}
              autocomplete="shipping address-line4"
            />
          )}
        />
      </Cell>
      <Cell columns={2}>
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Input
              {...field}
              labelText={labelPhoneNumber}
              onChange={value => field.onChange(handlePhoneNumberFormat(value))}
              status={error?.message && 'error'}
              error={error?.message}
              ref={ref}
              autocomplete="shipping tel"
            />
          )}
        />
      </Cell>
      <Cell columns={2}>
        <Controller
          control={control}
          name="smsUpdates"
          render={({ field: { ref, ...field }, fieldState: { error } }) => (
            <Checkbox
              {...field}
              checked={field.value}
              label={labelSMSUpdates}
            />
          )}
        />
        <SMSTermsAndConditions>
          {labelSMSTerms + ' View '}
          <SMSTermsAndConditionsLink href="/terms">
            Terms
          </SMSTermsAndConditionsLink>
          {' & '}
          <SMSTermsAndConditionsLink href="/privacy-policy">
            Privacy Policy
          </SMSTermsAndConditionsLink>
        </SMSTermsAndConditions>
      </Cell>
    </Grid>
  );
};
ShippingAddress.displayName = 'ShippingAddress';
ShippingAddress.propTypes = {
  labelFirstName: PropTypes.string,
  labelLastName: PropTypes.string,
  labelAddress: PropTypes.string.isRequired,
  labelApt: PropTypes.string.isRequired,
  labelZipCode: PropTypes.string.isRequired,
  labelCity: PropTypes.string.isRequired,
  labelState: PropTypes.string.isRequired,
  stateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ),
  labelPhoneNumber: PropTypes.string.isRequired,
  labelSMSUpdates: PropTypes.string.isRequired,
  labelSMSTerms: PropTypes.string.isRequired,
};
