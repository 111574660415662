'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const SMSTermsAndConditions = styled.div`
  ${props => props.theme.typography.label};
  padding: ${props => props.theme.spacing(1)};
  color: ${props => props.theme.colors.text.secondary};
`;

export const SMSTermsAndConditionsLink = styled(Link)`
  color: ${props => props.theme.colors.text.secondary};
  text-decoration: underline;
`;
