import React from 'react';
import PropTypes from 'prop-types';
import { ErrorText } from '../ErrorText';
import { InputWrapper, InputContainer, Input, Label } from './styles.js';

export const Checkbox = ({
  name,
  onChange,
  onBlur,
  label,
  checked,
  disabled,
  status,
  error,
}) => (
  <>
    <InputWrapper>
      <Input
        id={name}
        name={name}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        $status={status}
        onChange={() => onChange(!checked)}
        onBlur={() => onBlur?.()}
      />
      <Label htmlFor={name} $status={status}>
        {label}
      </Label>
    </InputWrapper>
    {error && (
      <InputContainer>
        <ErrorText>{error}</ErrorText>
      </InputContainer>
    )}
  </>
);

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.node,
  status: PropTypes.oneOf(['error', 'disabled']),
  error: PropTypes.string,
};
