import React from 'react';
import { TooltipIcon } from '../TooltipIcon';

export const PasswordTooltip = () => (
  <TooltipIcon name="Display password requirements" placement="top">
    A valid password must contain at least 8 characters, one uppercase, one
    number and one special character
  </TooltipIcon>
);

PasswordTooltip.displayName = 'PasswordTooltip';
