import * as yup from 'yup';
import { containsPoBox } from 'po-box';
import { FIELD_REQUIRED_MSG } from '../../validation';

const ZIPCODE_REGEX = /^[0-9]{5}(?:-[0-9]{4})?$/;

const PHONE_REGEX = /^\(?([2-9][0-9]{2})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/;

const name = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required(FIELD_REQUIRED_MSG)
    .label('First name'),
  lastName: yup.string().trim().required(FIELD_REQUIRED_MSG).label('Last name'),
});

export const phoneNumberValidation = yup
  .string()
  .required('Please enter a valid phone number, including the area code.')
  .matches(PHONE_REGEX, 'Not a valid phone number')
  .label('Phone number');
export const addressSchema = yup.object().shape({
  address: yup
    .string()
    .trim()
    .required('Please enter a valid address.')
    .label('Address')
    .test('Contains PoBox', 'Sorry! We cannot ship to PO boxes.', address => {
      return !containsPoBox(address);
    }),
  apt: yup.string().label('Apt/Suite').max(30),
  zipCode: yup
    .string()
    .trim()
    .required('Please enter a valid zip-code.')
    .matches(ZIPCODE_REGEX, 'Not a valid zip code')
    .label('Zip code'),
  city: yup
    .string()
    .trim()
    .required('Please enter a valid city.')
    .label('City'),
  state: yup
    .string()
    .trim()
    .required(FIELD_REQUIRED_MSG)
    .max(2)
    .label('Please enter a valid state.'),
  phoneNumber: phoneNumberValidation,
  smsUpdates: yup.bool().label('Send me SMS updates'),
});

export const addressWithNameSchema = addressSchema.concat(name);
