import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ui/button';
import { SmolLink } from '@web/atoms';
import { Modal } from '../Modal';
import { Header, Container, Paragraph, SpanContainer } from './styles.js';

export const ServerSideErrorModal = ({
  onCloseModal,
  shown,
  headerText,
  errorMessage,
  variant = 'primary',
  showCloseButton = false,
}) => {
  return (
    <Modal
      ariaLabel="Server Side Error Notification Form"
      onCloseModal={onCloseModal}
      shown={shown}
    >
      <Container>
        <Header variant={variant}>{headerText}</Header>
        <Paragraph>
          {errorMessage} If you continue to experience this issue, contact
          <SmolLink href="mailto:caninecare@myollie.com" target="_blank">
            &nbsp;Canine Care for assistance.
          </SmolLink>
        </Paragraph>
        {showCloseButton && (
          <SpanContainer>
            <Button
              id={'server-side-error-modal-close-button'}
              aria-label={'Server Side Error Modal Close button'}
              onClick={onCloseModal}
              fullWidth={true}
            >
              Okay
            </Button>
          </SpanContainer>
        )}
      </Container>
    </Modal>
  );
};

ServerSideErrorModal.displayName = 'ServerSideErrorModal';

ServerSideErrorModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  shown: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  variant: PropTypes.string,
  showCloseButton: PropTypes.bool,
};
