'use client';
import styled from 'styled-components';
import { generateContentIconStyle } from '@ui/icons';
import Checkmark from './Checkmark.svg';
import CheckmarkDisabled from './Checkmark-disabled.svg';

export const InputWrapper = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  position: relative;
`;

export const Label = styled.label`
  ${({ theme }) => theme.typography.body}
  margin-left: ${({ theme }) => theme.spacing(7)};
  color: ${({ theme, $status }) =>
    $status !== 'error'
      ? theme.colors.text.primary
      : theme.colors.status.errorPrimary};
  display: flex;
  white-space: nowrap;
`;

export const Input = styled.input`
  ${({ theme }) => theme.visuallyHidden}

  + label {
    display: block;
    cursor: pointer;
    padding-left: ${({ theme }) => theme.spacing(2)};

    &::before {
      content: ' ';
      border-radius: 5px;
      border: 1px solid
        ${({ theme, $status }) =>
          $status !== 'error'
            ? theme.colors.border.dark
            : theme.colors.status.errorPrimary};
      background-color: ${({ theme }) => theme.colors.background.lightest};
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      visibility: visible;
    }
  }
  &:checked + label::before {
    align-items: center;
    background: ${({ theme }) => theme.colors.neutral.darkerFur};
    border: 1px solid ${({ theme }) => theme.colors.neutral.darkerFur};
    content: url(${Checkmark.src});
    color: ${({ theme }) => theme.colors.text.primary};
    display: flex;
    justify-content: center;
  }

  &:disabled + label {
    color: ${({ theme }) => theme.colors.text.tertiary};
    &::before {
      background: ${({ theme }) => theme.colors.background.disabled};
      border: 1px solid ${({ theme }) => theme.colors.background.disabled};
    }
  }

  &:focus + label::before,
  &:focus:checked + label::before {
    border: 2px solid ${({ theme }) => theme.colors.hero.spinach};
    margin: -1px;
  }

  &:disabled:checked + label::before {
    content: url(${CheckmarkDisabled.src});
    background: ${({ theme }) => theme.colors.background.disabled};
    border: 1px solid ${({ theme }) => theme.colors.background.disabled};
  }

  &:hover:enabled + label::before,
  &:hover:enabled:checked + label::before {
    border: 2px solid ${({ theme }) => theme.colors.hero.spinach};
    margin: -1px;
  }
`;

export const RoundedCheckboxLabel = styled.label`
  align-items: center;
  background: ${({ checked, disabled, theme }) => {
    return disabled
      ? 'transparent'
      : checked
      ? theme.colors.secondary.egg
      : theme.colors.background.base;
  }};
  border-radius: 200px;
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? 'transparent' : theme.colors.border.dark};
  display: grid;
  grid-template-columns: 30px 1fr;
  height: 62px;
  padding: ${({ theme }) => theme.spacing(4)};
  text-align: center;

  ${({ theme }) => theme.typography.body}

  &:hover,
  &:focus-within {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    border: 2px solid
      ${({ theme, disabled }) =>
        disabled ? 'transparent' : theme.colors.hero.spinach};
    margin: 0 -1px;
  }

  &::before {
    ${({ checked }) =>
      checked
        ? generateContentIconStyle('radiobox-marked')
        : generateContentIconStyle('radiobox-blank')};
    ${({ disabled }) => disabled && generateContentIconStyle('close')};
    width: 20px;
    height: 20px;
    background-color: ${({ theme }) => theme.colors.border.dark};
  }
`;

export const InputContainer = styled.div`
  display: block;
`;
