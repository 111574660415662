'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const StyledBoxSummary = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  border: 1px solid
    ${props =>
      props.errorBorder
        ? props.theme.colors.status.errorPrimary
        : props.theme.transparent};
  padding: ${props => props.theme.spacing(6, 7, 6, 10)};
  background: ${props => props.theme.colors.background.lightest};
  margin-top: ${props => props.theme.spacing(8)};
  border-radius: ${props => props.theme.spacing(8)};
  width: 100%;
  @media ${props => props.theme.tablet} {
    display: grid;
    grid-row: auto auto;
    grid-column: auto auto;
  }
`;

export const Top = styled.div`
  display: flex;
  @media ${props => props.theme.tablet} {
    grid-row: 1;
    grid-column: 1;
  }
`;

export const SummaryInfo = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-right: ${props => props.theme.spacing(6)};
  flex-direction: column;
  max-width: ${props => props.theme.spacing(18)};
  @media ${props => props.theme.tablet} {
    max-width: ${props => props.theme.spacing(22)};
  }
`;

export const Headline = styled.h2`
  ${props => props.theme.typography.subheading1};
  width: 100%;
`;

export const Discount = styled.div`
  margin: ${props => props.theme.spacing(1, 0, 2, 0)};
`;

export const RecipesInfoContainer = styled.p`
  display: flex;
  width: 100%;
`;

export const RecipesInfoLabel = styled.span`
  ${props => props.theme.typography.bodyBold};
  padding-right: ${props => props.theme.spacing(2)};
`;

export const SelectedRecipes = styled.span`
  ${props => props.theme.typography.body};
`;

export const PacksContainer = styled.p`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const Packs = styled.span`
  ${props => props.theme.typography.bodyBold};
  padding-right: ${props => props.theme.spacing(2)};
`;

export const SelectedPacks = styled.span`
  ${props => props.theme.typography.body};
`;

export const MealPrepTypeContainer = styled.p`
  display: flex;
  width: 100%;
  align-items: center;
`;
export const MealPrepTypeLabel = styled.span`
  ${props => props.theme.typography.bodyBold};
  padding-right: ${props => props.theme.spacing(2)};
`;
export const SelectedMealPrepType = styled.span`
  ${props => props.theme.typography.body};
`;

export const Checkout = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(2)};
  flex: 1 0 auto;
  width: 100%;
  margin-top: ${props => props.theme.spacing(3)};
  @media ${props => props.theme.tablet} {
    width: fit-content;
    margin-top: 0;
    grid-row: 1;
    grid-column: 2;
  }
`;

export const CheckoutInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const StyledTotalAmount = styled.p`
  color: ${props => props.theme.colors.text.secondary};
  padding-right: ${props => props.theme.spacing(1)};
  ${props => props.theme.typography.heading6};
  font-weight: normal;
  text-decoration-line: line-through;
  text-align: right;
`;

export const StyledAmount = styled.div`
  ${props => props.theme.typography.heading6};
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spacing(1)};
  color: ${props => props.theme.colors.text.primary};
`;

export const ErrorLink = styled(Link)`
  color: ${props => props.theme.colors.status.errorPrimary};
  text-decoration: underline;
`;
