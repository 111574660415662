import React from 'react';
import PropTypes from 'prop-types';
import { VisuallyHidden } from '../VisuallyHidden';
import { RoundedCheckboxLabel } from './styles.js';

export const RoundedCheckbox = ({
  value,
  onChange,
  label,
  checked,
  disabled,
}) => {
  return (
    <RoundedCheckboxLabel
      htmlFor={`${value}-checkbox-input`}
      checked={checked}
      disabled={disabled}
    >
      <VisuallyHidden>
        <input
          type="checkbox"
          id={`${value}-checkbox-input`}
          name={value}
          checked={checked && !disabled}
          disabled={disabled}
          onChange={onChange}
        />
      </VisuallyHidden>
      {label}
    </RoundedCheckboxLabel>
  );
};

RoundedCheckbox.displayName = 'RoundedCheckbox';

RoundedCheckbox.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
